import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import data from "./questions.json";

type DataType = keyof typeof data;
type GroupType = typeof data[keyof typeof data];

type QuestionProps = {
  group: GroupType,
};

const Question = ({group}: QuestionProps) => {
  const [selectedQuestion, setSelectedQuestion] = useState<number>(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const changeSelect = useCallback(() => {
    let index;
    if (group.length <= 1) {
      index = 0;
    }
    else {
      do {
        index = Math.floor(Math.random() * group.length);
      } while(selectedQuestion === index);
    }
    setShowAnswer(false);
    setSelectedQuestion(index);
  }, [selectedQuestion, group.length]);
  useEffect(() => {
    setSelectedQuestion(0);
  }, [group]);

  const index = selectedQuestion < group.length ? selectedQuestion : 0;

  return <div>
    <p>{group[index].q}</p>
    {showAnswer ? <p>{group[index].a}</p> : null}
    <div>
      <button onClick={() => setShowAnswer(!showAnswer)}>
        {showAnswer ? "解答を隠す": "解答を表示"}
      </button>
    </div>
    <div>
      <button onClick={changeSelect}>問題を変更する</button>
    </div>
  </div>
}

function App() {
  const [selectedGroup, setSelectedGroup] = useState<DataType>(Object.keys(data)[0] as DataType);
  const onChange = (group: DataType) => {
    setSelectedGroup(group);
  };
  return (
    <div>
      <select onChange={(e) => onChange(e.target.value as DataType)}>
        {Object.keys(data).map((key) => <option key={key} value={key}>{key}</option>)}
      </select>
      <Question group={data[selectedGroup]} />
    </div>
  );
}

export default App;
